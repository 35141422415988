export const BEHAVIOR_TRANSFORMERS = Object.freeze([
  // use only lowercase names
  "composer-position:correct-scroll-position",
  "composer-position:editor-touch-move",
  "discovery-topic-list-load-more",
  "full-page-search-load-more",
  "post-menu-toggle-like-action",
  "topic-list-item-click",
]);

export const VALUE_TRANSFORMERS = Object.freeze([
  // use only lowercase names
  "admin-reports-show-query-params",
  "bulk-select-in-nav-controls",
  "category-available-views",
  "category-description-text",
  "category-display-name",
  "composer-service-cannot-submit-post",
  "header-notifications-avatar-size",
  "home-logo-href",
  "home-logo-image-url",
  "home-logo-minimized",
  "invite-simple-mode-topic",
  "latest-topic-list-item-class",
  "mentions-class",
  "more-topics-tabs",
  "move-to-topic-merge-options",
  "move-to-topic-move-options",
  "navigation-bar-dropdown-icon",
  "navigation-bar-dropdown-mode",
  "parent-category-row-class",
  "parent-category-row-class-mobile",
  "post-menu-buttons",
  "post-menu-collapsed",
  "post-text-selection-prevent-close",
  "small-user-attrs",
  "tag-separator",
  "topic-list-class",
  "topic-list-columns",
  "topic-list-header-sortable-column",
  "topic-list-item-class",
  "topic-list-item-expand-pinned",
  "topic-list-item-mobile-layout",
  "topic-list-item-style",
  "site-setting-enable-welcome-banner",
  "site-setting-search-experience",
]);
